<template>
    <super-admin-auth-layout>
        <div class="mt-3 pb-5">
            <div class="fl-x fl-j-c mb-4"><i class="fa fa-check-circle success-icon text-success"></i></div>
            <div>
                <h3 class="mb-3 fs-lg-4 text-center">Password Has been Updated</h3>
                <div class="text-center mb-6">
                    Your new password has been updated. <br>
                    Please to continue.
                </div>
                <btn class="px-5 py-3 font-inter-medium" block text="Login" loading-text="Validating..." size=""
                     :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </super-admin-auth-layout>
</template>

<script>
import SuperAdminAuthLayout from '@/views/auth/super-admin-auth/SuperAdminAuthPageLayout';
import urls from '@/data/urls';

export default {
    name       : 'SuperAdminPasswordUpdateSuccess',
    components : { SuperAdminAuthLayout },
    data () {
        return {
            loading      : false,
            loginUrl     : urls.auth.login,
            errorMessage : '',
            model        : {
                email    : '',
                password : ''
            },
            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    }
};
</script>

<style scoped lang="scss">
.success-icon{
    font-size: 5rem;
}
</style>
